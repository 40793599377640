<template>
  <div id="offer-commission-categories">
    <div v-if="!commissions.length" class="text-center text-primary my-2">
      <b-overlay :show="offerCommissionLoading">
        <b-button
          v-if="$can('OPERATOR_SUBSCRIPTION_ADD') && offerEdition"
          id="offer-commission-categories-add"
          variant="outline-primary"
          size="md"
          @click="addCategoriesGroup()"
        >
          <span class="text-nowrap">
            <feather-icon icon="PlusIcon" class="mr-25" />
            {{ $t('offer.commission.add_new_group') }}
          </span>
        </b-button>
      </b-overlay>
    </div>
    <div v-else ref="form" class="m-2 repeater-form hide-scroll" :style="{ height: trHeight }">
      <div v-for="commission in commissions" :id="commission.id" :key="commission.id" ref="row">
        <b-card class="border box-shadow-0" no-body>
          <b-row v-if="offerEdition">
            <b-col cols="12" lg="10" order="2" order-lg="1" class="align-items-center justify-content-start">
              <validation-provider
                #default="{ errors }"
                :name="$t('offer.commission.categories')"
                rules="required"
                :vid="`offer-commission-categories-${commission.id}-provider`"
              >
                <b-form-group
                  :label="$t('offer.commission.categories')"
                  :label-for="`offer-commission-categories-${commission.id}`"
                  class="m-2 validation-required"
                >
                  <b-overlay :show="offerCommissionLoading">

                    <category-tree-select
                      :id="`offer-commission-categories-${commission.id}`"
                      v-model="categoriesSelected[commission.id]"
                      :value-id="categories.id"
                      :lang="$store.state.appConfig.lang"
                      :multiple="true"
                      @close="val => editCategoriesGroup(val, commission.id)"
                    />

                    <b-tooltip
                      v-if="errors[0]"
                      show
                      :target="`offer-commission-categories-${commission.id}-category-tree-select`"
                      placement="bottom"
                      variant="light"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-tooltip>
                  </b-overlay>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="offerEdition" cols="12" lg="2" order="1" order-lg="2" class="d-flex align-items-center justify-content-end mt-2">
              <b-overlay :show="offerCommissionLoading">
                <b-button
                  v-if="$can('OPERATOR_SUBSCRIPTION_ADD') && offerEdition"
                  id="offer-commission-categories-add"
                  :disabled="offerCommissionLoading"
                  class="btn-icon mr-2"
                  size="md"
                  @click="addCategoriesGroup()"
                >
                  <span class="text-nowrap mr-25">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    {{ $t('offer.commission.add_new_group') }}
                  </span>
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
          <div v-else class="align-items-center justify-content-end m-2">
            <span> {{ $t('offer.commission.categories') }} : </span>
            <b-badge v-for="category in commission.categories" :key="category.id" pill variant="light-info" class="mr-1">
              {{ category.name | trans }}
            </b-badge>
          </div>

          <OfferPartCommissionSlices
            :slices="commission.slices.length ? commission.slices : []"
            :offer-commission-loading="offerCommissionLoading"
            :offer-id="offerId"
            :offer-commission-id="commission.id"
            :offer-edition="offerEdition"
            @slicesUpdated="$emit('slicesUpdated')"
          />
          <b-row>
            <b-col>
              <b-overlay :show="offerCommissionLoading">
                <b-button
                  v-if="$can('OPERATOR_SUBSCRIPTION_DELETE') && offerEdition"
                  id="offer-commission-categories-add"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  :disabled="offerCommissionLoading"
                  class="btn-icon mb-1 ml-2"
                  variant="outline-danger"
                  @click="removeCategoriesGroup(commission.id)"
                >
                  <span class="text-nowrap mr-25">
                    <feather-icon icon="TrashIcon" class="mr-25" />
                    {{ $t('action.delete') }}
                  </span>
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { heightTransition } from '@core/mixins/ui/transition'
import {
  postOfferCommissionCategoryRequest,
  patchOfferCommissionCategoryRequest,
  deleteOfferCommissionCategoryRequest,
} from '@/request/globalApi/requests/offerRequests'
import { fetchCategoriesRequest } from '@/request/globalApi/requests/categoryRequests'

import CategoryTreeSelect from '@/components/CategoryTreeSelect.vue'

import OfferPartCommissionSlices from './OfferPartCommissionSlices.vue'

export default {
  name: 'OfferPartCommissionCategories',

  components: {
    OfferPartCommissionSlices,
    CategoryTreeSelect,
  },

  mixins: [heightTransition],

  props: {
    commissions: {
      type: Array,
      required: true,
    },
    offerId: {
      type: Number,
      default: null,
    },
    offerCommissionLoading: {
      type: Boolean,
      required: true,
    },
    offerEdition: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      categories: {},
      isSortDirDesc: false,
      categoriesSelected: [],
    }
  },

  mounted() {
    this.APIFetchCategories()
  },

  methods: {
    addCategoriesGroup() {
      postOfferCommissionCategoryRequest(this.offerId, {
        categoriesIds: [],
      }).then(() => {
        this.$emit('categoriesGroupUpdated')
      })
    },

    APIFetchCategories() {
      fetchCategoriesRequest()
        .then(response => {
          const { categories } = response.data
          this.categories = categories

          this.commissions.forEach(commission => {
            this.categoriesSelected[commission.id] = commission.categories
          })
        })
    },

    editCategoriesGroup(categories, id) {
      patchOfferCommissionCategoryRequest(this.offerId, id, {
        categoriesIds: this.extractId(categories),
      })
    },

    removeCategoriesGroup(id) {
      deleteOfferCommissionCategoryRequest(this.offerId, id).then(() => {
        this.$emit('categoriesGroupUpdated')
      })
    },

    // TODO: perform it with `categories.map(cat => cat.id)`
    extractId(categories) {
      const categoriesIds = []
      categories.forEach(category => {
        categoriesIds.push(category.id)
      })
      return categoriesIds
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
.repeater-form {
  overflow: scroll;
  transition: 0.35s height;
  max-height: 500px;
}
</style>
